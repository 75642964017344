<template>
  <div class="home">
    <div style="height: 15px;width: 100%"></div>
    <div class="home_header">
      <div class="head_left">
        <!--  订单总数  -->
        <div class="orderTotalNumber" v-loading="orderTotalNumber">
          <order-total-number :loading.sync="orderTotalNumber" />
        </div>
        <!--  交易金额  -->
        <div class="tradeAmount" v-loading="tradeAmountLoading">
          <trade-amount :loading.sync="tradeAmountLoading" />
        </div>
        <!--  累计用户数  -->
        <div class="users" v-loading="usersLoading">
          <users :loading.sync="usersLoading" />
        </div>
        <!--  服务单数  -->
        <div class="serveOrder" v-loading="serveOrderLoading">
          <serve-order :loading.sync="serveOrderLoading" />
        </div>
        <!--  进账金额  -->
        <div class="payoutAmount" v-loading="payoutAmountLoading">
          <payout-amount :loading.sync="payoutAmountLoading" />
        </div>
        <div class="viewers" v-loading="viewersLoading">
          <viewers :loading.sync="viewersLoading" />
        </div>
      </div>
      <div class="head_right ">
        <!--    公司信息    -->
        <company-info />
      </div>
    </div>
    <div style="height: 20px;width: 100%"></div>
    <div class="home_footer">
        <general-chart />
        <rank-list />
    </div>
  </div>
</template>

<script>
const orderTotalNumber = () => import('./orderTotalNumber/index.vue')
const tradeAmount = () => import('./tradeAmount/index.vue')
const users = () => import('./users/index.vue')
const serveOrder = () => import('./serveOrder/index.vue')
const payoutAmount = () => import('./payoutAmount/index.vue')
const viewers = () => import('./viewers/index.vue')
const companyInfo = () => import('./companyInfo/index.vue')
const generalChart = () => import('./generalChart/index.vue')
const rankList = () => import('./rankList/index.vue')

export default {
  components:{
    orderTotalNumber,
    tradeAmount,
    users,
    serveOrder,
    payoutAmount,
    viewers,
    companyInfo,
    generalChart,
    rankList
  },
  data(){
    return {
      orderTotalNumber:true,
      tradeAmountLoading:true,
      usersLoading:true,
      serveOrderLoading:true,
      payoutAmountLoading:true,
      viewersLoading:true,
    }
  },


}
</script>

<style lang="scss" scoped>
.home{
  display: flex;
  flex-direction: column;
}
.home_header{
  display: flex;
  width: 100%;
  flex: 1;
  overflow: hidden;
}

.head_left{
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, minmax(75px, 1fr));
  grid-template-rows: repeat(2, minmax(100px, 1fr));
  grid-gap: 20px;
  box-sizing: border-box;

  .orderTotalNumber,
  .tradeAmount,
  .users,
  .serveOrder,
  .payoutAmount,
  .viewers{
    position: relative;
    background: #fff;
    box-sizing: border-box;
  }
}
.head_right {
  min-width: 350px;
  max-width: 25%;
  background: #fff;
  margin-left: 20px;
  overflow: auto;
}
.home_footer{
  flex: 1;
  width: 100%;
  display: flex;
}
@media (max-width: 1200px) {
  .head_left{
    flex-direction: column;
    grid-template-columns: repeat(2, minmax(75px, 1fr));
    grid-template-rows: repeat(3, minmax(200px, 1fr));
    grid-gap: 10px;
    overflow: auto;
  }
}
</style>